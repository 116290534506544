// Custom.scss
// Option A: Include all of Bootstrap

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

// fusv-disable
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default;
// fusv-enable

$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$silver:  #c0c0c0 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;
$gold:    #daa520 !default;
$navy:    #000080 !default;
$lightblue: #add8e6 !default;
$forestgreen: #228b22 !default;
$deeppink:  #ff1493 !default;
$mediumpurple: #9370db !default;
$navy:     #000080 !default;
$springgreen: #00ff7f !default;
$bisque:    #ffe4c4 !default;

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;

// scss-docs-start theme-colors-map
$my-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "silver":     $silver,
  "teal":       $teal,
  "cyan":       $cyan,
  "light":      $light,
  "dark":       $dark,
  "white":      $white,
  "gray":       $gray-600,
  "gray-500":	  $gray-500,
  "gray-700":	  $gray-700,
  "gray-200":	  $gray-200,
  "gray-300":	  $gray-300,
  "gray-400":   $gray-400,
  "gray-dark":  $gray-800,
  "gold":       $gold,
  "black":      $black,
  "light-blue": $lightblue,
  "forest-green": $forestgreen,
  "deep-pink":  $deeppink,
  "medium-purple": $mediumpurple,
  "navy":       $navy,
  "spring-green": $springgreen,
  "bisque":     $bisque
) !default;
// scss-docs-end theme-colors-map

$colors: $my-colors !default;
$theme-colors: $my-colors !default;
$table-variants: $my-colors !default;

$enable-shadows: true !default;
$enable-gradients: true !default;

@import "bootstrap";

